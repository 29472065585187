<template>
  <div>
    <v-form
      v-if="$store.state.orderDetil"
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-card>
        <v-card-text>
          <p class="mb-0">
            รูปแบบการส่ง
          </p>
          <hr class="mt-3 mb-3" />

          <v-row
            v-if="$store.state.orderData.length > 0"
            style="margin-top: 10px"
          >
            <v-col
              md="3"
              sm="3"
              cols="12"
              class="d-flex align-center"
            >
              <h3>วิธีการส่งสินค้า</h3>
            </v-col>
            <v-col
              md="3"
              sm="3"
              cols="12"
            >
              <v-select
                v-if="$store.state.orderDetil"
                v-model="$store.state.orderDetil.transport_type_id"
                outlined
                dense
                hide-details
                label="กรุณาเลือกวิธีการส่งสินค้า"
                :items="transportTypeItems"
                item-text="transport_type_name"
                :disabled="!(($store.state.user.member_id && ($store.state.orderDetil.order_status == 'PT' || $store.state.orderDetil.order_status == 'PC' ||$store.state.orderDetil.order_status == 'DF')) || !$store.state.user.member_id)"
                :rules="[v => !!v || 'กรุณาใสจำนวน']"
                required
                item-value="transport_type_id"
                class="mt-2"
                @change="settransport()"
              ></v-select>
            </v-col>
            <!-- วิธีการส่งสินค้า -->
            <v-col
              md="3"
              sm="3"
              cols="12"
              class="d-flex align-center"
            >
              <h3>ค่าจัดส่งสินค้า</h3>
            </v-col>
            <v-col
              md="3"
              sm="3"
              cols="12"
            >
              <v-text-field
                v-model="$store.state.orderDetil.transport_expense_price"
                label="ค่าจัดส่ง(บาท)"
                readonly
                type="number"
                :disabled="!(($store.state.user.member_id && ($store.state.orderDetil.order_status == 'PT' || $store.state.orderDetil.order_status == 'PC' ||$store.state.orderDetil.order_status == 'DF')) || !$store.state.user.member_id)"
                outlined
                dense
                hide-details
                placeholder="ค่าจัดส่ง"
              ></v-text-field>
            </v-col>
            <v-col
              md="3"
              sm="3"
              cols="12"
              class="d-flex align-center"
            >
              <h3>จำนวนสินค้า</h3>
            </v-col>
            <v-col
              md="3"
              sm="3"
              cols="12"
            >
              <v-text-field
                v-model="amount"
                label="จำนวน(ชิ้น)"
                type="number"
                outlined
                readonly
                dense
                hide-details
                :disabled="!(($store.state.user.member_id && ($store.state.orderDetil.order_status == 'PT' || $store.state.orderDetil.order_status == 'PC' ||$store.state.orderDetil.order_status == 'DF')) || !$store.state.user.member_id)"
                placeholder="จำนวน(ชิ้น)"
              ></v-text-field>
            </v-col>
            <v-col
              v-if="!$store.state.user.member_id"
              md="3"
              sm="3"
              cols="12"
              class="d-flex align-center"
            >
              <h3>ส่วนลด</h3>
            </v-col>
            <v-col
              v-if="!$store.state.user.member_id"
              md="3"
              sm="3"
              cols="12"
            >
              <v-text-field
                v-model="$store.state.orderDetil.order_price_discount"
                label="ส่วนลด"
                type="number"
                outlined
                dense
                hide-details
                min="0"
                :max="parseInt(total)"
                :disabled="!(($store.state.user.member_id && ($store.state.orderDetil.order_status == 'PT' || $store.state.orderDetil.order_status == 'PC' ||$store.state.orderDetil.order_status == 'DF')) || !$store.state.user.member_id)"
                placeholder="ส่วนลด"
              ></v-text-field>
            </v-col>
            <!-- ยอดสุทธิ -->
            <v-col
              md="3"
              sm="3"
              cols="12"
              class="d-flex align-center"
            >
              <h3>ยอดรวมสุทธิ</h3>
            </v-col>
            <v-col
              md="3"
              sm="3"
              cols="12"
            >
              <!--order_price_discount-->
              <!--                            <h4>{{// parseFloat((total+total_importune+totalConfigs) || 0) +-->
              <!--                            parseFloat($store.state.orderDetil.transport_expense_price || 0)}} (บาท)</h4>-->
              <v-text-field
                v-model="total"
                label="ราคา (บาท)"
                type="number"
                outlined
                readonly
                dense
                hide-details
                :disabled="!(($store.state.user.member_id && ($store.state.orderDetil.order_status == 'PT' || $store.state.orderDetil.order_status == 'PC' ||$store.state.orderDetil.order_status == 'DF')) || !$store.state.user.member_id)"
                placeholder="ราคา (บาท)"
              ></v-text-field>
            </v-col>
            <v-col
              md="12"
              sm="12"
              cols="12"
              class="pt-1 pl-0"
            >
              <div class="form-group col-md-12">
                <div
                  v-for="(item,index) in configs"
                  :key="index"
                  class="mt-3 "
                >
                  <span
                    v-if="item.id === 2"
                    style="display: flex;"
                  >
                    <!--                     :value="item.id"-->
                    <!--                  เร่งด้วน-->
                    <v-checkbox
                      v-model="item.check"
                      style="margin-top: -6px;"
                      value="true"
                      @click="ConfigImportune()"
                    >
                    </v-checkbox>
                    <span style="color: #ff4d4d"> {{ item.name }}</span>
                    <!--                  <b> มีค่าบริการเพิ่ม{{ item.value }} </b>-->
                    <p
                      v-if="item.check"
                      style="font-size: 13px;color: #0b0e0f;font-weight: 700"
                    >
                      {{ item.detail }}
                    </p>
                  </span>
                  <div
                    v-if="(item.id === 1 || item.id === '1')"
                    style="display: flex;"
                  >
                    <v-checkbox
                      v-model="item.check"
                      style="margin-top: -6px;"
                      :value="item.id"
                      hide-details
                      dense
                      @click="ConfigIsReview()"
                    ></v-checkbox>
                    <span style="color: #ff4d4d"> {{ item.name }} </span>
                    <b> มีค่าบริการเพิ่ม {{ item.value }}</b>
                    <div v-if="item.check && item.id === 1">
                      <!--                      <input   type="hidden" checked v-model="is_review1">-->
                      <p
                        v-if="item.check"
                        style="font-size: 13px;color: #0b0e0f;font-weight: 700"
                      >
                        {{ item.detail }}
                      </p>
                    </div>
                  </div>

                  <!--                  <div  style="display: flex;" v-if="(item.id === 3  || item.id === '3')">-->
                  <!--                    <v-checkbox-->
                  <!--                      style="margin-top: -6px;"-->
                  <!--                      v-model="item.check"-->
                  <!--                      :value="item.id"-->
                  <!--                      hide-details-->
                  <!--                      dense-->
                  <!--                      @click="ConfigIsReview2()"></v-checkbox>-->
                  <!--                    <span style="color: #ff4d4d"> {{ item.name }} </span>-->
                  <!--                    <div v-if="item.check && (item.id === 3  || item.id === '3')">-->
                  <!--                      &lt;!&ndash;                    <input type="hidden" checked v-model="is_review2">&ndash;&gt;-->
                  <!--                      <p v-if="item.check"-->
                  <!--                         style="font-size: 13px;color: #0b0e0f;font-weight: 700">-->
                  <!--                        {{ item.detail }} </p>-->
                  <!--                    </div>-->
                  <!--                  </div>-->

                  <!--                  <div style="display: flex;" v-if="item.id != 1 &&  item.id != 2 &&  item.id != 3">-->
                  <div
                    v-if="item.id != 1 && item.id != 2"
                    style="display: flex;"
                  >
                    <v-checkbox
                      v-model="item.check"
                      style="margin-top: -6px;"
                      hide-details
                      value="true"
                    ></v-checkbox>
                    <span style="color: #ff4d4d"> {{ item.name }} </span>
                    <b> มีค่าบริการเพิ่ม {{ item.value }} </b>
                    <p
                      v-if="item.check"
                      style="font-size: 13px;color: #0b0e0f;font-weight: 700"
                    >
                      {{ item.detail }}
                    </p>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>

        <hr class="mb-5" />

        <v-card-text>
          <!--          <div v-if="type_pay && type_pay == '1'">-->

          <!--&lt;!&ndash;            <hr class="mt-3 mb-3"/>&ndash;&gt;-->
          <!--&lt;!&ndash;            <p class="mb-0">โอนเข้าบัญชี</p>&ndash;&gt;-->
          <!--            <v-select v-model="$store.state.orderDetil.PT_BankID" dense outlined hide-details-->
          <!--                      label="เลือกธนาคาร" :items="bankList"-->
          <!--                      item-text="BankName" item-value="BankID"-->
          <!--                      class="mt-2"></v-select>-->

          <!--&lt;!&ndash;            @change="getBankShow()"&ndash;&gt;-->

          <!--            &lt;!&ndash; ถ้าเลือกพร้อมเพย์ส่วนนี้จะแสดง &ndash;&gt;-->
          <!--            <div v-if="$store.state.orderDetil.bank">-->
          <!--              &lt;!&ndash; เลขบัญชี &ndash;&gt;-->
          <!--              <p class="mb-2 mt-3">เลขที่บัญชี: {{-->
          <!--                  $store.state.orderDetil.bank.account_number-->
          <!--                }}</p>-->
          <!--              &lt;!&ndash; ชื่อบัญชี &ndash;&gt;-->
          <!--              <p class="mb-2">ชื่อบัญชี: <span> {{-->
          <!--                  $store.state.orderDetil.bank.account_name-->
          <!--                }}</span>-->
          <!--              </p>-->

          <!--              &lt;!&ndash; เริ่มวันที่โอน &ndash;&gt;-->
          <!--              &lt;!&ndash; <p class="mb-2">-->
          <!--              วันที่โอน:-->
          <!--            </p> &ndash;&gt;-->
          <!--              &lt;!&ndash;            <v-menu ref="menu1" :close-on-content-click="false" transition="scale-transition" offset-y hide-details&ndash;&gt;-->
          <!--              &lt;!&ndash;              max-width="290px" min-width="auto">&ndash;&gt;-->
          <!--              &lt;!&ndash;              <template v-slot:activator="{ on, attrs }">&ndash;&gt;-->
          <!--              &lt;!&ndash;                <v-text-field v-model="payment.PT_date" label="Date" hint="MM/DD/YYYY format" persistent-hint&ndash;&gt;-->
          <!--              &lt;!&ndash;                  :rules="[v => !!v || 'กรุณาเลือกวันที่โอน']" required v-bind="attrs" outlined dense&ndash;&gt;-->
          <!--              &lt;!&ndash;                  @blur="date = parseDate(dateFormatted)" v-on="on"></v-text-field>&ndash;&gt;-->
          <!--              &lt;!&ndash;              </template>&ndash;&gt;-->
          <!--              &lt;!&ndash;              <v-date-picker&ndash;&gt;-->

          <!--              &lt;!&ndash;                no-title @input="menu1 = false">&ndash;&gt;-->
          <!--              &lt;!&ndash;              </v-date-picker>&ndash;&gt;-->
          <!--              &lt;!&ndash;              <DatePicker&ndash;&gt;-->
          <!--              &lt;!&ndash;                value-type="format"&ndash;&gt;-->
          <!--              &lt;!&ndash;                format="YYYY-MM-DD"&ndash;&gt;-->
          <!--              &lt;!&ndash;                label="วันที่โอน"&ndash;&gt;-->
          <!--              &lt;!&ndash;                v-model="$store.state.orderDetil.PT_date"&ndash;&gt;-->
          <!--              &lt;!&ndash;              ></DatePicker>&ndash;&gt;-->
          <!--              &lt;!&ndash;            </v-menu>&ndash;&gt;-->

          <!--              &lt;!&ndash;              <DatePicker&ndash;&gt;-->
          <!--              &lt;!&ndash;                class="mb-3"&ndash;&gt;-->
          <!--              &lt;!&ndash;                v-model="$store.state.orderDetil.PT_date"&ndash;&gt;-->
          <!--              &lt;!&ndash;                style="width: 100%"&ndash;&gt;-->
          <!--              &lt;!&ndash;                placeholder="กรุณาเลือกวันที่โอน"&ndash;&gt;-->
          <!--              &lt;!&ndash;                value-type="format"&ndash;&gt;-->
          <!--              &lt;!&ndash;                format="DD-MM-YYYY"&ndash;&gt;-->
          <!--              &lt;!&ndash;                label="Date"&ndash;&gt;-->
          <!--              &lt;!&ndash;                title="วันที่โอน"&ndash;&gt;-->
          <!--              &lt;!&ndash;              ></DatePicker>&ndash;&gt;-->
          <!--              &lt;!&ndash;              &lt;!&ndash; จบวันที่โอน &ndash;&gt;&ndash;&gt;-->

          <!--              &lt;!&ndash;              &lt;!&ndash; เวลาโอน &ndash;&gt;&ndash;&gt;-->
          <!--              &lt;!&ndash;              &lt;!&ndash; <p class="mb-2">เวลาที่โอน:</p> &ndash;&gt;&ndash;&gt;-->
          <!--              &lt;!&ndash;              <v-text-field label="เวลาที่โอน" v-model="$store.state.orderDetil.PT_time" type="time"&ndash;&gt;-->
          <!--              &lt;!&ndash;                            outlined dense hide-details&ndash;&gt;-->
          <!--              &lt;!&ndash;                            :rules="[v => !!v || 'กรุณากรอกเวลาที่โอน']" required placeholder=""&ndash;&gt;-->
          <!--              &lt;!&ndash;                            class="mb-2">&ndash;&gt;-->
          <!--              &lt;!&ndash;              </v-text-field>&ndash;&gt;-->
          <!--              &lt;!&ndash; จบเวลาโอน &ndash;&gt;-->

          <!--              &lt;!&ndash; ยอดโอน &ndash;&gt;-->
          <!--              &lt;!&ndash; <p class="mb-2">ยอดโอน:</p> &ndash;&gt;-->
          <!--              &lt;!&ndash;              <v-text-field label="ยอดโอน" v-model="$store.state.orderDetil.PT_price_amt"&ndash;&gt;-->
          <!--              &lt;!&ndash;                            type="number" outlined dense&ndash;&gt;-->
          <!--              &lt;!&ndash;                            :rules="[v => !!v || 'กรุณากรอกยอดที่โอน']" required hide-details&ndash;&gt;-->
          <!--              &lt;!&ndash;                            placeholder="">&ndash;&gt;-->
          <!--              &lt;!&ndash;              </v-text-field>&ndash;&gt;-->

          <!--              &lt;!&ndash;              <v-file-input v-model="$store.state.orderDetil.file_slip" show-size label="แนบสลิป">&ndash;&gt;-->
          <!--              &lt;!&ndash;              </v-file-input>&ndash;&gt;-->
          <!--              &lt;!&ndash;              <p v-if="$store.state.orderDetil.PT_slip"><a :href="$store.state.orderDetil.PT_slip"&ndash;&gt;-->
          <!--              &lt;!&ndash;                                                           target="_blank">กดดูไฟล์สลิป</a></p>&ndash;&gt;-->

          <!--            </div>-->
          <!--          </div>-->
          <v-radio-group
            v-model="type_pay"
            label="การจ่ายเงิน"
          >
            <v-radio
              value="1"
              label="โอนเงิน"
            ></v-radio>
            <v-radio
              value="3"
              :label="'เครดิตเงินในกระเป่าออนไลน์ ( ' + $store.state.credit_customers + ' )'"
            ></v-radio>

            <v-radio
              value="4"
              :label="'เครดิตสินค้า(ชิ้น) ( ' + $store.state.credit_products + ' )'"
            ></v-radio>
            <!--            <v-radio-->
            <!--              value="5"-->
            <!--              label="เงินสด"></v-radio>-->
          </v-radio-group>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            v-if="($store.state.user.member_id && ($store.state.orderDetil.order_status == 'PT' || $store.state.orderDetil.order_status == 'PC' ||$store.state.orderDetil.order_status == 'DF')) || !$store.state.user.member_id"
            :disabled="(!type_pay) || !$store.state.orderDetil.transport_type_id"
            color="primary"
            @click="submitValidationPyment()"
          >
            บันทึกข้อมูล
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import DatePicker from 'vue2-datepicker'
import bankAPI from '../../../../services/bank'
import user from '../../../../services/user'
import store from '@/store/index.js'
import orderAPI from '../../../../services/order'
import mainData from '@/services/mainData'
import config from '@/services/config'
import transportTypeAPI from '@/services/transportType'

export default {
  components: { Swal, DatePicker },
  props: {
    getOrderDetail: {},
    transportTypeItems: [],
  },
  data() {
    return {
      valid: true,
      configs: [],
      paymentTime: [
        v => !!v || 'payment time is required.',
      ],
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      bankShow: store.state.bankShow,
      bankList: store.state.bankList,
      selectBankking: {},
      dateFormatted: this.formatDate(new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)),
      payment: {},
      menu1: false,
      type_pay: '1',
    }
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date)
    },
    amount() {
      const total = []
      Object.entries(this.$store.state.orderData).forEach(([key, val]) => {
        if (val.product_amount) {
          total.push(parseInt(val.product_amount))
        } else {
          total.push(0)
        }
      })

      return total.reduce((total, num) => parseInt(total) + parseInt(num), 0)
    },
    sub_total: {
      get() {
        const total = []
        Object.entries(this.$store.state.orderData).forEach(([key, val]) => {
          if (val.product_price) {
            total.push(parseInt(parseInt(val.product_price) * parseInt(val.product_amount)))
          } else {
            total.push(0)
          }
        })

        return total.reduce((total, num) => parseInt(total) + parseInt(num), 0)
      },

    },
    total: {
      get() {
        const total = []
        Object.entries(this.$store.state.orderData).forEach(([key, val]) => {
          if (val.product_price) {
            total.push(parseInt(parseInt(val.product_price) * parseInt(val.product_amount)))
          } else {
            total.push(0)
          }
        })
        Object.entries(this.configs).forEach(([key, val]) => {
          console.log(val, val.value)
          if ((val.check && val.check > 0) || (val.check && (val.check == true || val.check == 'true'))) {
            if (val.id != 2) {
              total.push(parseFloat(val.value) * parseFloat(this.amount))
            }

            // console.log(parseFloat(val.value),7777 ,parseFloat(this.amount))
          }

          // if (val.product_price) {
          //   total.push(parseInt(parseInt(val.product_price) * parseInt(val.product_amount)))
          // } else {
          //   total.push(0)
          // }
        })
        if (this.$store.state.orderDetil.transport_expense_price && this.$store.state.orderDetil.transport_expense_price > 0) {
          total.push(parseFloat(this.$store.state.orderDetil.transport_expense_price))
        }

        if (this.vat() && this.vat() > 0) {
          total.push(parseFloat(this.vat()))
        }

        if (this.$store.state.orderDetil.order_price_discount && this.$store.state.orderDetil.order_price_discount > 0) {
          total.push(-(parseFloat(this.$store.state.orderDetil.order_price_discount)))
        }
        if (this.importune) {
          total.push(parseFloat(this.total_importune()))
        }

        return total.reduce((total, num) => parseFloat(total) + parseFloat(num), 0)
      },
    },

    // setter
    set(newValue) {
      // your setter here

    },
  },

  watch: {
    date(val) {
      this.dateFormatted = this.formatDate(this.date)
    },
  },
  updated() {
    // store.state.orderData = this.orderData;
    // store.state.orderDetil = this.order_detail
  },
  created() {
  },
  mounted() {
    this.getBank()
    this.getConfig()

    // this.getSetting();
  },

  methods: {
    submitValidationPyment() {
      // console.log(this.$refs.form.resetValidation(),1111)
      // this.$refs.form.resetValidation()
      this.orderUpdatePayment()
    },
    validateForm() {
      console.log('---validateForm----')

      // this.orderUpdatePayment()
    },
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')

      return `${month}/${day}/${year}`
    },
    parseDate(date) {
      if (!date) return null

      const [month, day, year] = date.split('/')

      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    clickEditData() {
      // this.buttonStart = true
      // this.buttonGoToNotPay = !this.buttonGoToNotPay
      this.changEditData = true
    },
    getBankShow() {
      // this.payment.PT_BankID
      // let PT_BankID = this.$store.state.orderDetil.PT_BankID
      // let $this = this

      // var data = {
      //   'order_id': this.$store.state.orderDetil.order_id,
      //   'PT_BankID': PT_BankID
      // }
      // orderAPI
      //   .orderUpdatePayment(data)
      //   .then(res => {
      //     if (res.status === 200) {
      //       console.log(this.$store.state.orderData, 12354)
      //       this.getOrderDetail()
      //       Swal.fire({
      //         icon: 'success',
      //         title: 'บันทึกข้อมูลเรียบร้อย',
      //         showConfirmButton: false,
      //         timer: 1500
      //       })
      //     }
      //     // removrToken
      //     user.removrToken(res)
      //   })
      //   .catch(err => {
      //
      //     Swal.fire({
      //       icon: 'error',
      //       title: err.message,
      //       showConfirmButton: false,
      //       timer: 1500
      //     })
      //   })
      // if (PT_BankID) {
      //   bankAPI
      //     .show(PT_BankID)
      //     .then(res => {
      //       console.log(res,344)
      //       if (res.status === 200) {
      //         $this.order_detail.bank = res.data
      //         console.log($this.order_detail.bank)
      //       }
      //
      //       // removrToken
      //       user.removrToken(res)
      //     })
      //     .catch(error => {
      //       console.log(error,1234)
      //     })
      // }
      //
      // console.log(this.payment,3333)
    },
    getBank() {
      bankAPI
        .get()
        .then(res => {
          if (res.status === 200) {
            this.bankList = res.data
          }

          // removrToken
          user.removrToken(res)
        })
        .catch(error => {
        })
    },

    // showOrderMember() {
    //   // this.orderData = [];
    //   let $this = this
    //   orderAPI
    //     .showOrder(store.state.member.member_id)
    //     .then(res => {
    //       if (res.status === 200) {
    //         console.log(res.data,222)
    //         $this.payment = res.data
    //       }
    //       // removrToken
    //       user.removrToken(res)
    //     })
    //     .catch(error => {
    //
    //     })
    // },
    orderUpdatePayment() {
      this.orderData = []

      // if (this.type_pay == 1 || this.type_pay == '1') {
      //   // var format_date = (this.$store.state.orderDetil.PT_date).split('-')
      //   // this.$store.state.orderDetil.PT_date = format_date[2] + '-' + format_date[1] + '-' + format_date[0]
      //   if (!this.$store.state.orderDetil.PT_BankID){
      //     Swal.fire({
      //       icon: 'error',
      //       title: "กรุณาเลือกบัญชีธนาคาร",
      //       showConfirmButton: false,
      //       timer: 1500
      //     })
      //     return false;
      //   }
      // }

      this.$store.state.orderDetil.type_pay = this.type_pay
      this.$store.state.orderDetil.PT_price_amt = this.total
      this.$store.state.orderDetil.order_expense = this.total
      this.$store.state.orderDetil.importune = this.importune

      const data = {

        totalConfigs: this.totalConfigs(),
        orders_configs: JSON.stringify(this.configs),
        total_importune: this.total_importune(),
        transport_expense: this.$store.state.orderDetil.transport_expense_price,

        // transport_setadd_status : this.transport_setadd_status,
      }

      // data.append('transport_expense_price', this.transport_expense);
      // data.append('transport_setadd_status', this.transport_type.transport_setadd_status);
      orderAPI
        .orderUpdatePayment(this.$store.state.orderDetil, data)
        .then(res => {
          if (res.status === 200) {
            if (res.data.success) {
              Swal.fire({
                icon: 'success',
                title: 'บันทึกข้อมูลเรียบร้อย',
                showConfirmButton: false,
                timer: 1500,
              })

              this.$store.state.checkUpdateClicked = true
              this.getOrderDetail()
              this.$store.state.step = 4
            } else {
              Swal.fire({
                icon: 'error',
                title: res.data.message,
                showConfirmButton: false,
                timer: 1500,
              })
            }
          }

          // removrToken
          user.removrToken(res)
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            title: err.message,
            showConfirmButton: false,
            timer: 1500,
          })
        })
    },
    getConfig() {
      const data = {
        search: '',
        size: 9999,
        page: 1,
        sortBy: '',
        sortDesc: '',

      }

      // ?search=${data.search}&size=${data.size}&page=${data.page}&sortBy=${data.sortBy}&sortDesc=${data.sortDesc}
      config.get(data, 'Y').then(res => {
        this.configs = res.data.data.data
      }).catch(err => {

      })
    },

    ConfigImportune() {
      if (this.importune) {
        this.importune = false
      } else {
        this.importune = true
      }
    },
    ConfigIsReview() {
      if (this.is_review) {
        this.is_review = false
      } else {
        this.is_review = true
      }
    },
    ConfigIsReview2() {
      if (this.is_review2) {
        this.is_review2 = false
      } else {
        this.is_review2 = true
      }
    },
    settransport() {
      if (this.$store.state.orderDetil.transport_type_id) {
        let amount = 0
        for (let i = 0; i < this.$store.state.orderDetil.order_detail.length; i++) {
          amount += (this.$store.state.orderDetil.order_detail[i].product.product_type.weight * this.$store.state.orderDetil.order_detail[i].product_amount)
        }
        transportTypeAPI
          .apiTransportExpensePriceRate(amount, this.$store.state.orderDetil.transport_type_id)
          .then(res => {
            if (res.status === 200) {
              this.$store.state.orderDetil.transport_expense_price = res.data
              this.validateForm()
            }

            // removrToken
            user.removrToken(res)
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    vat() {
      let total = 0
      Object.entries(this.configs).forEach(([key, val]) => {
        if (val.check && val.id == 5) {
          total = (parseFloat(this.sub_total) + parseFloat(this.$store.state.orderDetil.transport_expense_price))
        }
      })

      return total * 0.07
    },
    totalConfigs() {
      const total = []
      Object.entries(this.configs).forEach(([key, val]) => {
        total.push(0)
        if (val.check && val.id === 1) {
          if (this.amount < 6) {
            total.push(this.amount * 40)
            val.value = this.amount * 40
          } else if (this.amount > 5 && this.amount < 11) {
            val.value = this.amount * 35
            total.push(this.amount * 35)
          } else {
            total.push(this.amount * 30)
            val.value = this.amount * 30
          }
        } else {
          total.push(0)
        }
      })

      return total.reduce((total, num) => parseInt(total) + parseInt(num), 0)
    },

    total_importune() {
      const total = []
      if (this.importune) {
        total.push(0)

        // Object.entries(this.data).forEach(([key, val]) => {
        if (this.amount < 13) {
          total.push(this.amount * 20)
        } else if (this.amount > 12 && this.amount < 50) {
          total.push(this.amount * 15)
        } else {
          total.push(this.amount * 10)
        }

        // });
      }

      return total.reduce((total, num) => parseInt(total) + parseInt(num), 0)
    },
  },
}
</script>

<style scoped>
a {
  text-decoration: none;
}

img {
  height: 5.15rem;
  width: 4.235rem;
  border-radius: 50%;
  transform: translateX(-10px);
}

li {
  list-style-type: none;
}

hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}

.imgQR {
  width: 6.75rem;
  height: 6.75rem;
  border-radius: 5px;
}
</style>
