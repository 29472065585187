<template>
  <v-stepper v-model="$store.state.step">
    <v-stepper-header>
      <v-stepper-step
        :complete="$store.state.step > 1"
        step="1"
      >
        รายการสินค้า
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step
        :complete="$store.state.step > 2"
        step="2"
      >
        การขนส่ง
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step
        :complete="$store.state.step > 3"
        step="3"
      >
        ชำระเงิน
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step step="4">
        เสร็จสิ้น
      </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content
        step="1"
        class="reset-padding-top"
      >
        <v-card
          class="mb-12"
          color="grey"
        >
          <!--          <StepOrder-->
          <!--            :getOrderDetail="getOrderDetail"-->
          <!--            :transportTypeItems="transportTypeItems"-->
          <!--            :brandList="brandList"-->
          <!--            :productTypes="productTypes"-->
          <!--            :Step="Step"/>-->

          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <!--            style="padding-bottom: 80px;"-->
            <v-card-text>
              <h2
                v-if="$store.state.orderData && $store.state.orderData.length > 0"
                class="mb-5 text-center"
              >
                รายการสินค้า
              </h2>
              <!--      <v-alert v-if="$store.state.orderDetil && $store.state.orderDetil.order_inv" dense text-->
              <!--               type="warning">-->
              <!--        หมายเลขออเดอร์นี้ <strong> {{ $store.state.orderDetil.order_inv }} </strong> จะหมดอายุในเวลา-->
              <!--        : <strong>-->
              <!--        {{ $store.state.orderDetil.order_date_time }} </strong>-->
              <!--      </v-alert>-->
              <v-alert
                v-if="validate"
                type="error"
              >
                {{ errorMessage }}
              </v-alert>
              <hr
                v-if="$store.state.orderData && $store.state.orderData.length > 0"
                class="mb-5"
              />
              <div class="">
                <li
                  v-for="(item, index) in $store.state.orderData"
                  :key="item.id"
                >
                  <v-row>
                    <v-col
                      md="3"
                      sm="12"
                      cols="12"
                      class="img_proauctRes"
                    >
                      <center>
                        <img
                          v-if="!item.pattern || item.pattern.location_files || item.pattern.example_picture "
                          :src="item.pattern && item.pattern.example_picture ? item.pattern.example_picture : item.pattern.location_files"
                          :alt="item.pattern && item.pattern.pattern_name ? item.pattern.pattern_name : ''"
                          onclick="window.open(this.src)"
                        />
                        <v-file-input
                          v-model="item.changeFile"
                          accept="image/png, image/jpeg, image/bmp"
                          placeholder="แก้ไขไฟล์"
                          label="แก้ไขไฟล์"
                          @change="changeFile(index)"
                        ></v-file-input>
                        <!-- <p><a>แก้ไขไฟล์</a></p> -->
                      </center>
                    </v-col>
                    <v-col
                      md="6"
                      sm="12"
                      cols="12"
                    >
                      <!-- กรุณาเลือกประเภทสินค้า -->

                      <v-select
                        v-model="item.product_type_id"
                        outlined
                        dense
                        hide-details
                        label="กรุณาเลือกประเภทสินค้า"
                        :disabled="!(($store.state.user.member_id && ($store.state.orderDetil.order_status == 'PT' || $store.state.orderDetil.order_status == 'PC' ||$store.state.orderDetil.order_status == 'DF')) || !$store.state.user.member_id) || true"

                        :items="productTypes"
                        item-text="product_type_name"
                        item-value="product_type_id"
                        class="mt-2"
                        :rules="[v => !!v || 'กรุณาเลือกประเภทสินค้า']"
                        required
                        @change="changeProuctType(index)"
                      ></v-select>
                      <!-- กรุณาเลือกประเภทสินค้า -->

                      <!-- กรุณาเลือกยี่ห้อ -->
                      <div v-if="item.product_type_id">
                        <v-select
                          v-model="item.phone_brand_id"
                          outlined
                          :disabled="(!item.product_type_id) || !(($store.state.user.member_id && ($store.state.orderDetil.order_status == 'PT' || $store.state.orderDetil.order_status == 'PC' ||$store.state.orderDetil.order_status == 'DF')) || !$store.state.user.member_id) || true"
                          dense
                          hide-details
                          label="กรุณาเลือกยี่ห้อ"
                          :items="brandList"
                          item-text="phone_brand_name"
                          :rules="[v => !!v || 'กรุณาเลือกยี่ห้อ']"
                          required
                          item-value="phone_brand_id"
                          class="mt-2"
                          @change="changeModel(index)"
                        ></v-select>
                      </div>
                      <!-- กรุณาเลือกยี่ห้อ -->

                      <!-- สินค้าต้องการสั่งผลิต -->
                      <div v-if="item.phone_brand_id && item.product && item.product.product">
                        <v-select
                          v-model="item.product_id"
                          :disabled="(!item.phone_brand_id) || !(($store.state.user.member_id && ($store.state.orderDetil.order_status == 'PT' || $store.state.orderDetil.order_status == 'PC' ||$store.state.orderDetil.order_status == 'DF')) || !$store.state.user.member_id) || true"
                          outlined
                          dense
                          hide-details
                          label="สินค้าต้องการสั่งผลิต"
                          :items="productList[index]"
                          item-text="product_name"
                          item-value="product_id"
                          :rules="[v => !!v || 'กรุณาเลือกรุ่น']"
                          required
                          class="mt-2"
                          @change="productPrice(index)"
                        >
                          <template v-slot:prepend-item>
                            <v-list-item>
                              <v-list-item-content>
                                <v-text-field
                                  v-model="searchTerm[index]"
                                  placeholder="Search"
                                  @input="searchProduct(index)"
                                ></v-text-field>
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                          </template>
                        </v-select>
                      </div>
                      <!-- สินค้าต้องการสั่งผลิต -->
                    </v-col>
                    <v-col
                      v-if="item.product_id"
                      md="3"
                      sm="12"
                      cols="12"
                    >
                      <div v-if="item.phone_brand_id && item.product && item.product.product">
                        <!-- ราคา -->
                        <v-text-field
                          v-model="item.product_price"
                          label="ราคา"
                          type="number"
                          :disabled="($store.state.orderDetil.order_status != 'PT' && $store.state.orderDetil.order_status != 'PC' && $store.state.orderDetil.order_status != 'DF') || $store.state.user.member_id != null"
                          outlined
                          dense
                          hide-details
                          placeholder="ราคา"
                          class="mt-1"
                          @change="changePrice(index)"
                        ></v-text-field>
                        <!-- ราคา -->

                        <!-- จำนวนคงเหลือ -->
                        <div v-if="item.product">
                          <v-text-field
                            v-model="item.product.remaining_online"
                            label="จำนวนคงเหลือ"
                            :disabled="!(($store.state.user.member_id && ($store.state.orderDetil.order_status == 'PT' || $store.state.orderDetil.order_status == 'PC' ||$store.state.orderDetil.order_status == 'DF')) || !$store.state.user.member_id) || true"
                            readonly
                            type="number"
                            outlined
                            dense
                            hide-details
                            placeholder="จำนวนคงเหลือ"
                            class="mt-1"
                          ></v-text-field>
                        </div>
                        <!-- จำนวนคงเหลือ   -->

                        <div v-if="item.product">
                          <v-text-field
                            v-model="item.product_amount"
                            label="จำนวน"
                            type="number"
                            :disabled="!(($store.state.user.member_id && ($store.state.orderDetil.order_status == 'PT' || $store.state.orderDetil.order_status == 'PC' ||$store.state.orderDetil.order_status == 'DF')) || !$store.state.user.member_id) || true"
                            :max="item.product.remaining_online"
                            min="1"
                            outlined
                            dense
                            hide-details
                            placeholder="จำนวน"
                            :rules="[v => !!v || 'กรุณาใสจำนวน']"
                            required
                            class="mt-1"
                          >
                          </v-text-field>
                          <!--                          @change="checkAmount(index)"-->
                        </div>

                        <!-- จำนวน -->
                        <div
                          v-if="(($store.state.user.member_id && ($store.state.orderDetil.order_status == 'PT' || $store.state.orderDetil.order_status == 'PC' ||$store.state.orderDetil.order_status == 'DF')) || !$store.state.user.member_id)"
                          class="d-flex align-center mt-2"
                        >
                          <v-icon
                            style="color: red"
                            class="me-1"
                            @click="remove(index)"
                          >
                            {{ icons.mdiDelete }}
                          </v-icon>
                        </div>
                        <!-- จำนวน -->
                      </div>
                    </v-col>
                    <hr>
                  </v-row>
                </li>
              </div>

              <div
                v-if="(($store.state.user.member_id && ($store.state.orderDetil.order_status == 'PT' || $store.state.orderDetil.order_status == 'PC' ||$store.state.orderDetil.order_status == 'DF')) || !$store.state.user.member_id)"
                class="mt-10"
              >
                <hr class="mt-5 mb-5" />
                <div class="text-center wapperContentBottom">
                  <v-menu
                    top
                    :offset-y="offset"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div class="wapperIconContent">
                        <v-btn
                          title="รูปอัพโหลดไฟล์เข้าระบบ"
                          color="secondary"
                          v-bind="attrs"
                          hide-details
                          v-on="on"
                        >
                          <v-icon
                            v-if="icons && icons.mdiPlus"
                            class="me-1"
                          >
                            {{ icons.mdiPlus }}
                          </v-icon>
                          เพิ่มอัพโหลดไฟล์
                        </v-btn>
                      </div>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(item, index) in itemUploadProduct"
                        :key="index"
                      >
                        <v-list-item-title>
                          <v-dialog
                            transition="dialog-top-transition"
                            width="1000"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <div>
                                <!--                                :on="index == 1 ? 'on' : ''"-->
                                <v-btn
                                  text
                                  v-bind="attrs"
                                  class="mt-5 testdialog"
                                  :disabled="item.disabled"
                                  @click="uploadLink(index)"
                                >
                                  <v-icon class="me-1">
                                    {{ item.icons }}
                                  </v-icon>
                                  {{ item.title }}
                                </v-btn>
                              </div>
                            </template>
                          </v-dialog>
                        </v-list-item-title>
                      </v-list-item>

                      <!--                      <v-list-item-title>-->
                      <!--                        <input ref="refInputEl" type="file" accept=".jpeg,.png,.jpg,GIF"-->
                      <!--                               class="uploadFile"-->
                      <!--                               hide-details-->
                      <!--                               multiple tabindex="-1" @change="uploadFile($event, 1)"/>-->
                      <!--                      </v-list-item-title>-->
                    </v-list>
                  </v-menu>
                  <!--                  <div class="mt-10">-->
                  <!--                    <v-btn-->
                  <!--                      style="float:right;"-->
                  <!--                      v-if="($store.state.user.member_id && ($store.state.orderDetil.order_status == 'PT' || $store.state.orderDetil.order_status == 'PC' ||$store.state.orderDetil.order_status == 'DF')) || !$store.state.user.member_id"-->
                  <!--                      color="primary" @click="validateForm()">-->
                  <!--                      บันทึกข้อมูล-->
                  <!--                    </v-btn>-->
                  <!--                  </div>-->
                </div>

                <!--                <v-row style="margin-top: 10px" v-if="$store.state.orderData.length > 0">-->
                <!--                  <v-col md="12" sm="12" cols="12" class="d-flex align-center">-->
                <!--                    <h3> รายละเอียดเพิ่มเติม</h3>-->
                <!--                  </v-col>-->
                <!--                  <v-col md="12" sm="12" cols="12">-->
                <!--                    <v-textarea-->
                <!--                      v-model="$store.state.orderDetil.order_detail_oth"-->
                <!--                      placeholder="รายละเอียดเพิ่มเติม"-->
                <!--                    ></v-textarea>-->
                <!--                    <div style="color: red;">-->
                <!--                      *เช่น ชื่อลูกค้าในเฟส หรือ ชื่อตัวแทน หรือเลขอ้างอิงการขายของท่าน-->
                <!--                      หากไม่ได้ส่งไปรษณีย์ ให้ระบบวันมารับที่ร้านที่ช่องนี้-->
                <!--                    </div>-->
                <!--                  </v-col>-->
                <!--                </v-row>-->
              </div>
            </v-card-text>
            <!--            <v-card-actions class="justify-end">-->
            <!--            </v-card-actions>-->
          </v-form>
        </v-card>

        <v-card-actions class="justify-end">
          <!-- <v-btn

            @click="Step = 1"
          >
            ย้อนกลับ
          </v-btn> -->
          <!-- {{this.$store.state.validate}} -->
          <v-btn
            :disabled="($store.state.validate) || (validateData(1))"
            @click="formsubmit(2,true)"
          >
            ถัดไป
          </v-btn>
        </v-card-actions>
      </v-stepper-content>
      <v-stepper-content
        step="2"
        class="reset-padding-top"
      >
        <v-card
          class="mb-12"
          color="grey lighten-1"
        >
          <StepDelivery
            :setting="setting"
            :get-order-detail="getOrderDetail"
          />
        </v-card>

        <v-card-actions class="justify-end">
          <v-btn @click="formsubmit(1)">
            ย้อนกลับ
          </v-btn>
          <v-btn
            :disabled="validateData(2) || $store.state.checkUpdateClicked == false"
            @click="formsubmit(3,true)"
          >
            ถัดไป
          </v-btn>
        </v-card-actions>
      </v-stepper-content>
      <v-stepper-content
        step="3"
        class="reset-padding-top"
      >
        <v-card
          class="mb-12"
          color="grey lighten-1"
        >
          <StepPayment
            :transport-type-items="transportTypeItems"
            :get-order-detail="getOrderDetail"
          />
        </v-card>
        <v-card-actions class="justify-end">
          <!-- <v-btn
            @click="Step = 3"
          >
            ย้อนกลับ
          </v-btn> -->
          <v-btn @click="formsubmit(2)">
            ย้อนกลับ
          </v-btn>
          <v-btn
            :disabled="validateData(4) || $store.state.orderDetil.order_status == 'DF' || $store.state.checkUpdateClicked == false"
            @click="formsubmit(4,true)"
          >
            ถัดไป
          </v-btn>
        </v-card-actions>
      </v-stepper-content>
      <v-stepper-content
        step="4"
        class="reset-padding-top"
      >
        <v-card
          class="mb-12"
          color="grey lighten-1"
        >
          <Success
            :transport-type-items="transportTypeItems"
            :brand-list="brandList"
            :product-types="productTypes"
            :product-list="productList"
            :get-order-detail="getOrderDetail"
          />
        </v-card>

        <v-card-actions class="justify-end">
          <!--          <v-btn-->
          <!--            v-if="(($store.state.user.member_id && ($store.state.orderDetil.order_status == 'PT' || $store.state.orderDetil.order_status == 'PC' ||$store.state.orderDetil.order_status == 'DF')) || !$store.state.user.member_id)"-->
          <!--            @click="formsubmit(3)">-->
          <!--            ย้อนกลับ-->
          <!--          </v-btn>-->
          <v-btn
            color="error"
            @click="formsubmit(-1)"
          >
            ปิด
          </v-btn>
          <v-btn
            v-if="(!$store.state.user.member_id)"
            color="primary"
            @click="openBill()"
          >
            ดูบิล
          </v-btn>
        </v-card-actions>
      </v-stepper-content>
    </v-stepper-items>
    <v-dialog
      v-model="dialogAddPicture"
      max-width="850"
    >
      <v-card>
        <v-card-title class="text-h5 mb-5">
          เพิ่มลายสินค้า
        </v-card-title>
        <v-card-text>
          <v-file-input
            v-model="editAddPicture.image_example"
            label="รูปตัวอย่าง"
          ></v-file-input>
          <small
            v-for="(err,index) in v$.editAddPicture.image_example.$silentErrors"
            v-if="v$.editAddPicture.image_example.$error"
            class="validate-err"
          >{{ index > 0 ? ', ' : '' }}
            {{ err.$message }}</small>

          <v-text-field
            v-model="editAddPicture.name"
            label="ชื่อลายสินค้าของคุณ *"
            placeholder="ชื่อลายสินค้าของคุณ *"
          ></v-text-field>
          <small
            v-for="(err,index) in v$.editAddPicture.name.$silentErrors"
            v-if="v$.editAddPicture.name.$error"
            class="validate-err"
          >{{ index > 0 ? ', ' : '' }}
            {{ err.$message }}</small>

          <v-file-input
            v-model="editAddPicture.file"
            label="ไฟล์"
          ></v-file-input>
          <small
            v-for="(err,index) in v$.editAddPicture.file.$silentErrors"
            v-if="v$.editAddPicture.file.$error"
            class="validate-err"
          >{{ index > 0 ? ', ' : '' }}
            {{ err.$message }}</small>

          <v-textarea
            v-model="editAddPicture.description"
            label="รายละเอียดสินค้า"
            placeholder="รายละเอียดสินค้า"
          ></v-textarea>
          <v-select
            v-if="pattern_product"
            v-model="editAddPicture.product_id"
            label="สินค้า"
            :items="pattern_product"
            :item-text="item => item.product_name +' - จำนวนคงเหลือ '+ item.remaining_online + ' (ชิ้น)' "
            item-value="product_id"
            placeholder="กรุณาพิมพ์ค้นหารุ่นที่สั่งผลิต"
            outlined
            dense
            hide-details
            class="mt-2"
            @input="add_pattern_product()"
          >
            <!--            item-text="product_name"-->
            <template v-slot:prepend-item>
              <v-list-item>
                <v-list-item-content>
                  <v-text-field
                    v-model="st"
                    placeholder="Search"
                    @input="product_search"
                  ></v-text-field>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
            </template>
            <!--            <template-->
            <!--              slot="selection"-->
            <!--              slot-scope="data"-->
            <!--            >-->
            <!--              &lt;!&ndash; HTML that describe how select should render selected items &ndash;&gt;-->
            <!--              {{ data }}-->
            <!--            </template>-->
          </v-select>
          <small
            v-for="(err,index) in v$.editAddPicture.product_id.$silentErrors"
            v-if="v$.editAddPicture.product_id.$error"
            class="validate-err"
          >{{ index > 0 ? ', ' : '' }}
            {{ err.$message }}</small>

          <v-text-field
            v-if="editAddPicture.product_id"
            v-model="editAddPicture.product_amount"
            class="mt=2"
            type="number"
            label="จำนวน *"
            placeholder="จำนวน *"
          ></v-text-field>
          <small
            v-for="(err,index) in v$.editAddPicture.product_amount.$silentErrors"
            v-if="v$.editAddPicture.product_amount.$error"
            class="validate-err"
          >{{ index > 0 ? ', ' : '' }}
            {{ err.$message }}</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            @click="closeAddPicture"
          >
            ยกเลิก
          </v-btn>
          <v-btn
            :disabled=" !editAddPicture.name || ! editAddPicture.image_example || !editAddPicture.file || !editAddPicture.product_id || !editAddPicture.product_amount"
            color="primary"
            @click="addPictureConfirm"
          >
            เพิ่ม
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogAddLink"
      max-width="850"
    >
      <v-card>
        <v-card-title class="text-h5 mb-5">
          เพิ่มลายสินค้าจากลิงก์
        </v-card-title>
        <v-card-text>
          <v-file-input
            v-model="editAddLink.image"
            label="รูปตัวอย่าง"
          ></v-file-input>
          <small
            v-for="(err,index) in v$.editAddLink.image.$silentErrors"
            v-if="v$.editAddLink.image.$error"
            class="validate-err"
          >{{ index > 0 ? ', ' : '' }}
            {{ err.$message }}</small>

          <v-text-field
            v-model="editAddLink.name"
            label="ชื่อลายสินค้าของคุณ *"
            placeholder="ชื่อลายสินค้าของคุณ *"
          ></v-text-field>
          <small
            v-for="(err,index) in v$.editAddLink.name.$silentErrors"
            v-if="v$.editAddLink.name.$error"
            class="validate-err"
          >{{ index > 0 ? ', ' : '' }}
            {{ err.$message }}</small>
          <v-text-field
            v-model="editAddLink.type_link_add_files"
            label="ลิงก์ไฟล์"
            placeholder="ลิ้งก์ไฟล์"
          ></v-text-field>
          <small
            v-for="(err,index) in v$.editAddLink.type_link_add_files.$silentErrors"
            v-if="v$.editAddLink.type_link_add_files.$error"
            class="validate-err"
          >{{ index > 0 ? ', ' : '' }}
            {{ err.$message }}</small>

          <v-textarea
            v-model="editAddLink.description"
            label="รายละเอียดสินค้า"
            placeholder="รายละเอียดสินค้า"
          ></v-textarea>
          <!--          <v-select-->
          <!--            label="สินค้า"-->
          <!--            v-if="pattern_product"-->
          <!--            v-model="pattern_product_data"-->
          <!--            :items="pattern_product"-->
          <!--            item-text="product_name"-->
          <!--            item-value="product_id"-->
          <!--            placeholder="กรุณาพิมพ์ค้นหารุ่นที่สั่งผลิต"-->
          <!--            @input="add_pattern_product()">-->
          <!--          </v-select>-->
          <v-select
            v-model="pattern_product_data"
            :items="pattern_product"
            item-text="product_name"
            item-value="product_id"
            placeholder="กรุณาพิมพ์ค้นหารุ่นที่สั่งผลิต"
            outlined
            dense
            hide-details
            class="mt-2"
            @input="add_pattern_product()"
          >
            <template v-slot:prepend-item>
              <v-list-item>
                <v-list-item-content>
                  <v-text-field
                    v-model="st"
                    placeholder="Search"
                    @input="product_search"
                  ></v-text-field>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
            </template>
          </v-select>
          <v-btn
            v-if="pattern_product_data"
            style="float: right"
            color="secondary"
            @click="addItem"
          >
            เพิ่มสินค้า
          </v-btn>

          <table
            v-if="editAddLink.list && editAddLink.list.length > 0"
            class="table mt-3"
          >
            <thead>
              <tr>
                <th class="text-center">
                  ลำดับ
                </th>
                <th class="text-center">
                  ชื่อสินค้า
                </th>
                <th class="text-center">
                  จำนวนสินค้าคงเหลือ(ชิ้น)
                </th>
                <th class="text-center">
                  ราคา(บาท)
                </th>
                <th>จำนวน(ชิ้น)</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(list,index) in editAddLink.list">
                <td class="text-center">
                  {{ index + 1 }}
                </td>
                <td class="text-center">
                  {{ list.name }}
                </td>
                <td class="text-center">
                  {{ list.amount }}
                </td>
                <td class="text-center">
                  {{ list.price }}
                </td>
                <td>
                  <v-text-field
                    v-model="list.quantity"
                    :max="list.amount"
                    min="1"
                    type="number"
                  ></v-text-field>
                </td>
                <td>
                  <v-icon
                    style="color: red"
                    class="me-1"
                    @click="removeItem(index)"
                  >
                    {{ icons.mdiDelete }}
                  </v-icon>
                </td>
              </tr>
            </tbody>
          </table>

          <small
            v-for="(err,index) in v$.editAddLink.list.$silentErrors"
            v-if="v$.editAddLink.list.$error"
            class="validate-err"
          >{{ index > 0 ? ', ' : '' }}
            {{ err.$message }}</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            @click="closeAddLink"
          >
            ยกเลิก
          </v-btn>
          <v-btn
            color="primary"
            @click="addLinkConfirm"
          >
            เพิ่ม
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <small
      class="ml-2 mb-2"
      style="color: red;margin-left: 15px;margin-bottom: 15px;"
    >* บิลมีอายุ 24 ช.ม. ในการสั่ง เกินกว่านี้บิลจะถูกยกเลิกอัตโนมัติ</small>
  </v-stepper>
</template>

<script>
import Swal from 'sweetalert2'
import { required } from '@vuelidate/validators'
import {
  mdiCloseCircle,
  mdiCloudUpload, mdiDelete,
  mdiImageAlbum,
  mdiMinusCircle, mdiPencil,
  mdiPlus,
  mdiPlusCircle,
} from '@mdi/js'
import { useVuelidate } from '@vuelidate/core'
import StepOrder from '../step-transit/order/StepOrder.vue'

// import StepPayment from '../step-transit/order/StepPayment.vue'
import StepDelivery from '../step-transit/order/StepDelivery.vue'
import StepPayment from '../step-transit/order/StepPayment.vue'
import Success from '../step-transit/order/Success.vue'
import store from '@/store/index.js'
import instance_order from '@/services/order'
import productType from '@/services/productType'
import user from '@/services/user'
import getModelAPI from '@/services/model'
import transportTypeAPI from '@/services/transportType'
import orderAPI from '@/services/order'
import mainData from '@/services/mainData'
import productAPI from '@/services/product'
import patternAPI from '@/services/pattern'
import orderDetailAPI from '@/services/orderDetail'
import productPriceAPI from '@/services/productPrice'

export default {
  setup() {
    return {
      v$: useVuelidate(),
      icons: {
        mdiPencil,
        mdiDelete,
      },
    }
  },
  components: {
    StepOrder,
    StepDelivery,
    StepPayment,
    Success,
  },
  data() {
    return {
      delayTimer: 0,
      type_add: 1,
      Step: 1,
      pattern_product_data: null,
      pattern_product: [],
      customerLatest: [],
      brandList: [],
      productTypes: [],
      transportTypeItems: [],
      orderData: store.state.orderData,
      orderDetil: store.state.orderDetil,
      validate: store.state.validate,
      dialogAddLink: false,
      dialogAddPicture: false,
      setting: {},
      valid: true,

      // validate: false,
      errorMessage: '',
      attrs: '',
      product: [],
      itemUploadProduct: [
        // {icons: mdiCloudUpload, title: 'อัพโหลดรูป', disabled: false},
        { icons: mdiPlusCircle, title: 'อัพโหลดรูป', disabled: false },
        { icons: mdiPlusCircle, title: 'อัพโหลดลิงก์', disabled: false },
      ],
      offset: true,
      selectTransition: '',
      priceAdd: 0,
      discount: 0,
      price: 0,
      productSelect: [],
      transport_type_id: '',
      transportTypePrice: 0,
      bankList: '',
      productList: [],
      productListCopy: [],
      editAddPicture: {
        image_example: null,
        name: '',
        file: null,
        product_id: null,
        description: '',
      },
      defaultEditAddPicture: {
        image_example: null,
        name: '',
        file: null,
        product_id: null,
        description: '',
      },
      editAddLink: {
        image: null,
        name: '',
        type_link_add_files: '',
        describe: '',
        list: [],
        product_amount: 0,
      },
      defaultEditAddLink: {
        image: null,
        name: '',
        type_link_add_files: '',
        describe: '',
        list: [],
        product_amount: 0,
      },
      st: '',
      searchTerm: [],
      icons: {
        mdiPlus,
        mdiPlusCircle,
        mdiMinusCircle,
        mdiCloseCircle,
        mdiCloudUpload,
        mdiImageAlbum,

        // mdiArchiveRemove,
        mdiDelete,
      },
    }
  },
  computed: {

    amount() {
      const total = []
      Object.entries(this.$store.state.orderData).forEach(([key, val]) => {
        if (val.product_amount) {
          total.push(parseInt(val.product_amount))
        } else {
          total.push(0)
        }
      })

      return total.reduce((total, num) => parseInt(total) + parseInt(num), 0)
    },
    total() {
      const total = []
      Object.entries(this.$store.state.orderData).forEach(([key, val]) => {
        if (val.product_price) {
          total.push(parseInt(parseInt(val.product_price) * parseInt(val.product_amount)))
        } else {
          total.push(0)
        }
      })

      return total.reduce((total, num) => parseInt(total) + parseInt(num), 0)
    },
  },
  updated() {
    this.orderDetil = store.state.orderDetil
    this.orderData = store.state.orderData
    this.validate = store.state.validate
  },
  validations() {
    const validate = {
      editAddPicture: {
        image_example: {},
        name: {},
        file: {},
        product_id: {},
        product_amount: 0,
      },
      editAddLink: {
        image: {},
        name: {},
        type_link_add_files: {},
        list: {},
        product_amount: 0,
      },
    }

    if (this.type_add == 1 || this.type_add == '1') {
      // validate.editAddPicture.image_example = {required};
      // validate.editAddPicture.name = {required};
      // validate.editAddPicture.file = {required};
      // validate.editAddPicture.product_id = {required};
    } else {
      validate.editAddLink.image = { required }
      validate.editAddLink.name = { required }
      validate.editAddLink.type_link_add_files = { required }
      validate.editAddLink.list = { required }
    }

    return validate
  },
  mounted() {
    this.$store.state.validate = false
    this.getProductType()
    this.getModel()
    this.getTransportType()

    // this.showOrderMember()
    this.$store.state.orderDetil.order_id = this.$route.params.id
    this.getOrderDetail()

    const vm = this
    const unwatch = this.$watch('$store.state.orderData', newQuestion => {
      vm.getProductList()
      unwatch()
    })

    // if (!this.$store.state.orderDetil.order_id) {
    //   console.log(222)
    //   this.showOrderMember()
    // }else{
    //   console.log(333)
    //   this.getOrderDetail()
    // }
  },
  methods: {
    validateData(step) {
      let error = false
      if (step == 1) {
        if ((!this.$store.state.orderData || this.$store.state.orderData.length == 0)) {
          error = true
        }

        for (let i = 0; i < this.$store.state.orderData.length; i++) {
          if (!this.$store.state.orderData[i].product_amount || this.$store.state.orderData[i].product_amount == 0) {
            error = true
          }
        }
      } else if (step == 2) {
        if (!this.$store.state.orderDetil.sender_name) {
          error = true
        }
        if (!this.$store.state.orderDetil.sender_address) {
          error = true
        }
        if (!this.$store.state.orderDetil.recipient_name) {
          error = true
        }
        if (!this.$store.state.orderDetil.recipient_address) {
          error = true
        }

        // if (!this.$store.state.orderDetil.order_detail_oth){
        //   error = true;
        // }
        // if (!this.$store.state.orderDetil.addressFile){
        //   error = true;
        // }
      } else if (step == 3) {
        // if (!this.$store.state.orderDetil.PT_BankID) {
        //   error = true;
        // }
        // if (!this.$store.state.orderDetil.PT_date) {
        //   error = true;
        // }
        // if (!this.$store.state.orderDetil.PT_time) {
        //   error = true;
        // }
        // if (!this.$store.state.orderDetil.PT_price_amt) {
        //   error = true;
        // }
        // if (!this.$store.state.orderDetil.PT_slip && !this.$store.state.orderDetil.file_slip) {
        //   error = true;
        // }
        // if (!this.$store.state.orderDetil.order_detail_oth){
        //   error = true;
        // }
        // if (!this.$store.state.orderDetil.addressFile){
        //   error = true;
        // }
      }

      return error
    },

    // showOrderMember() {
    //
    //   // this.orderData = [];
    //   let $this = this
    //   orderAPI
    //     .showOrder(this.$route.params.id)
    //     .then(res => {
    //       if (res.status === 200) {
    //         this.$store.state.orderDetil = res.data
    //         if (res.data.order_detail) {
    //           this.$store.state.orderData = res.data.order_detail
    //           this.$store.state.member = res.data.member
    //
    //           if (this.$store.state.orderData && this.$store.state.orderData.length > 0) {
    //             for (var i = 0; i < this.$store.state.orderData.length; i++) {
    //               this.$store.state.orderData[i].changeFile = null
    //             }
    //           }
    //           // $this.orderDetil = res.data
    //           // this.$store.state.orderDetil = $this.orderDetil
    //
    //           if (res.data.order_detail) {
    //             this.$store.state.orderData = res.data.order_detail
    //             // this.$store.commit('setOrderData', res.data.order_detail);
    //           }
    //         } else {
    //           this.$store.state.orderData = [];
    //         }
    //
    //
    //       }
    //       user.removrToken(res)
    //     })
    //     .catch(error => {
    //
    //     })
    // },
    getOrderDetail() {
      orderAPI
        .showOrderDetail(this.$store.state.orderDetil.order_id)
        .then(res => {
          if (res.status === 200) {
            this.orderDetil = res.data.data
            this.$store.state.orderDetil = res.data.data
            this.$store.state.credit_customers = res.data.credit_customers ? res.data.credit_customers : 0
            this.$store.state.credit_products = res.data.credit_products ? res.data.credit_products : 0

            // console.log(this.$store.state.orderDetil,111)
            this.$store.state.member = res.data.data.member

            // this.$store.state.step = 1
            // if ((this.$store.state.orderData && this.$store.state.orderData.length > 0 && !this.validateData(1)) && (this.$store.state.orderDetil.order_status == 'DF' && (!this.$store.state.orderDetil.sender_name || !this.$store.state.orderDetil.sender_address || !this.$store.state.orderDetil.recipient_name || !this.$store.state.orderDetil.recipient_address))) {
            //   // this.$store.state.step = 2
            // } else if (this.$store.state.orderDetil.order_status == 'DF' && (this.$store.state.orderDetil.sender_name && this.$store.state.orderDetil.sender_address && this.$store.state.orderDetil.recipient_name && this.$store.state.orderDetil.recipient_address)) {
            //   this.$store.state.step = 3
            // } else if (this.$store.state.orderDetil.order_status != 'DF') {
            //   this.$store.state.step = 4
            // }

            if (res.data.data.order_detail) {
              // this.$store.state.orderDetil = $this.orderDetil
              if (res.data.data.order_detail) {
                this.$store.state.orderData = res.data.data.order_detail
              }
            }

            this.product_search()
            this.getProductList()
          }
          user.removrToken(res)
        })
        .catch(error => {

        })
    },
    addItem() {
      const id = this.pattern_product_data
      let data = null
      Object.entries(this.pattern_product).forEach(([key, val]) => {
        if (val.product_id == id) {
          data = val
        }
      })
      this.editAddLink.list.push({
        product_id: data.product_id,
        name: data.product_name,
        amount: data.remaining_online,
        price: data.product_price,
        location_files: data.location_files,
        quantity: 1,
        product_price_old: data.product_price_old ?? 0,
        product_costs: data.product_costs ?? 0,
      })

      this.pattern_product_data = null

      // let data = {
      //   'phone_brand_name': product.phone_brand_name,
      //   'phone_model_name': product.phone_model_name,
      //   'product_code': product.product_code,
      //   'product_costs': product.product_costs,
      //
      //   'product_price': product.product_price,
      //   'set_price': 'N',
      //   'product_price_old': product.product_price_old,
      //
      //   'package_price_cost': product.package_price_cost,
      //   'product_id': product.product_id,
      //   'product_name': product.product_name,
      //   'product_amount': product.amount,
      //   'amount': product.amount,
      //   'product_type_id': product.product_type_id,
      //   'weight': product.weight,
      //   'example_picture': product.example_picture,
      //   'pattern_code': pattern.pattern_code,
      //   'pattern_status': pattern.pattern_status,
      //
      // }
    },
    removeItem(index) {
      this.editAddLink.list.splice(index, 1)
    },
    getProductType() {
      const data = {
        search: this.search,
      }
      productType
        .apiProductType(data)
        .then(res => {
          if (res.status === 200) {
            this.productTypes = res.data
          }

          // removrToken
          user.removrToken(res)
        })
        .catch(error => {

        })
    },
    getModel() {
      getModelAPI
        .getModel()
        .then(res => {
          if (res.status === 200) {
            this.brandList = res.data
          }

          // removrToken
          user.removrToken(res)
        })
        .catch(error => {
        })
    },
    getTransportType() {
      transportTypeAPI
        .get()
        .then(res => {
          if (res.status === 200) {
            this.transportTypeItems = res.data

            // console.log( this.transportTypeItems,1234)
          }

          // removrToken
          user.removrToken(res)
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            title: err.message,
            showConfirmButton: false,
            timer: 1500,
          })
        })
    },
    addPattern() {
      // var check = false;
      // if (this.order_type === 2 || this.order_type === '2') {
      //   if (!this.pattern_name) {
      //     this.validate_error.pattern_name = false
      //   } else {
      //     this.validate_error.pattern_name = true
      //   }
      //
      //   if (!this.file && (this.type_files === 2 || this.type_files === '2')) {
      //     this.validate_error.file = false
      //   } else {
      //     this.validate_error.file = true
      //   }
      //
      //   if (!this.type_link_add_files && (this.type_files === 1 || this.type_files === '1')) {
      //     this.validate_error.type_link_add_files = false
      //   } else {
      //     this.validate_error.type_link_add_files = true
      //   }
      //
      //   if (!this.validate_error.recipient_name || !this.validate_error.recipient_address || !this.validate_error.file || !this.validate_error.pattern_name || !this.validate_error.transport_type) {
      //     this.swalAlert('กรุณากรอกข้อมูลให้ครบถ้วน', 'error')
      //     return false;
      //   } else {
      //     check = true
      //   }
      //
      // }
      //
      //
      // if (check && this.order_type === 2 || this.order_type === '2') {
      //   let data = new FormData();
      //   data.append('user_id', this.customer_select.member_id);
      //   data.append('pattern_name', this.pattern_name);
      //   data.append('pattern_code', this.pattern_code);
      //   data.append('pattern_detail', this.pattern_detail);
      //   data.append('type_link_add_files', this.type_link_add_files);
      //   data.append('file', this.file);
      //   data.append('file_example_picture', this.file_example_picture);
      //   let config = {
      //     header: {
      //       'Content-Type': 'multipart/form-data'
      //     }
      //   }
      //   let loader = this.$loading.show({
      //     loader: 'dots',
      //     color: '#27ae60'
      //   })
      //   /*  let loader = this.$loading.show({
      //          loader: 'dots',
      //          color: '#27ae60'
      //      })*/
      //   /*   setTimeout(() => loader.hide(), 3 * 1000)*!/*/
      //   var $this = this
      //   axios.post('{{url('admin/addPattern')}}',
      //     data, config, {}
      // )
      // .then(function (response) {
      //     loader.hide()
      //     /*  if ($this.pattern_select && $this.pattern_product_list && $this.pattern_product_list != 0) {*/
      //     /* for (var i = 0; i < $this.pattern_product_list.length; i++) {
      //          var product_pattern = $this.pattern_product_list[i];
      //          let data = {
      //              'phone_brand_name': product_pattern.phone_brand_name,
      //              'phone_model_name': product_pattern.phone_model_name,
      //              'product_code': product_pattern.product_code,
      //              'product_costs': product_pattern.product_costs,
      //              'product_price': product_pattern.product_price,
      //              'product_id': product_pattern.product_id,
      //              'product_name': product_pattern.product_name,
      //              'product_amount': product_pattern.amount,
      //              'product_type_id': product_pattern.product_type_id,
      //          }*/
      //     $this.pattern_id = response.data.data.pattern_id;
      //     $this.pattern_code = response.data.data.pattern_code;
      //     $this.pattern_name = response.data.data.pattern_name;
      //     $this.pattern_img = response.data.data.location_files;
      //     $this.example_picture = response.data.data.example_picture;
      //     $this.save_pattern = true
      //     /*
      //  }*/
      //     /* } else {
      //          this.validate_error.pattern = false;
      //          this.swalAlert('เกิดข้อผิดพลาด', 'error')
      //          return false;
      //      }*/
      //
      //   })
      //     .catch(function (error) {
      //       console.log(error.message);
      //       /* swal({
      //            text: error.message,
      //            timer: 3000,
      //            icon: 'error',
      //            showConfirmButton: false,
      //            button: false
      //        })
      //        return false*/
      //
      //     });
      //
      //   // if(pattern_check){
      //   //
      //   //     this.data.push(pattern)
      //   // }
      //
      // }

    },
    add_product() {
      // set_prices กำหนดราคาให้ลูกค่า

      //
      // let $this = this
      //
      // if ($this.order_type === 2 || this.order_type === '2') {
      //   if (!$this.pattern_name) {
      //     $this.validate_error.pattern_name = false
      //   } else {
      //     $this.validate_error.pattern_name = true
      //   }
      //
      //   if (!$this.file && (this.type_files === 2 || $this.type_files === '2')) {
      //     $this.validate_error.file = false
      //   } else {
      //     $this.validate_error.file = true
      //   }
      //
      //   if (!$this.type_link_add_files && ($this.type_files === 1 || $this.type_files === '1')) {
      //     this.validate_error.type_link_add_files = false
      //   } else {
      //     $this.validate_error.type_link_add_files = true
      //   }
      //
      //   if (!$this.validate_error.recipient_name || !$this.validate_error.recipient_address || !$this.validate_error.file || !$this.validate_error.pattern_name || !$this.validate_error.transport_type) {
      //     this.swalAlert('กรุณากรอกข้อมูลให้ครบถ้วน', 'error')
      //     return false;
      //   }
      //
      // }
      const pattern = $this.pattern_select
      if ($this.pattern_select && $this.pattern_product_list && $this.pattern_product_list.length != 0) {
        let a = 0
        for (let i = 0; i < $this.pattern_product_list.length; i++) {
          a++
          const product = $this.pattern_product_list[i]

          const data = {
            phone_brand_name: product.phone_brand_name,
            phone_model_name: product.phone_model_name,
            product_code: product.product_code,
            product_costs: product.product_costs,

            product_price: product.price.product_price,
            set_price: 'N',
            product_price_old: product.product_price_old,

            package_price_cost: product.package_price_cost,
            product_id: product.product_id,
            product_name: product.name,
            product_amount: product.amount,
            amount: product.amount,
            product_type_id: product.product_type_id,
            weight: product.weight,
            example_picture: product.example_picture,
            pattern_code: pattern.pattern_code,
            pattern_status: pattern.pattern_status,

          }
          $this.product_type_id = product.product_type_id
          if ($this.order_type === 1 || $this.order_type === '1') {
            data.pattern_id = pattern.pattern_id
            data.pattern_code = pattern.pattern_code
            data.pattern_name = pattern.pattern_name
            data.pattern_img = pattern.pattern_img
          } else if ($this.order_type === 2 || $this.order_type === '2') {
            data.pattern_id = $this.pattern_id
            data.pattern_code = $this.pattern_code
            data.pattern_name = $this.pattern_name
            data.pattern_img = $this.pattern_img
            data.example_picture = $this.example_picture
          }
          let check = true
          if (!data.pattern_id) {
            check = false
            this.swalAlert('ไม่พบลายสินค้ากรุณาเลือกลายร้านหรือเพิ่มลายเพิ่มของคุณ', 'error')

            return false
          }
          if (check) {
            $this.data.push(data)

            // check อีกรอบ เมือ จำนวนชิ้นที่มากกว่า ในช่วงเรทราคาสินค้าที่ถูกลง
            // $this.OneMoreTimeSetPrice();

            if ($this.order_type === 1 || this.order_type === '1') {
              $this.sessionsOrderDetail()
            }
          }
        }

        if ($this.order_type === 2 || $this.order_type === '2') {
          $this.sessionsOrderDetail()
          $this.pattern_select = {}
          $this.pattern_product_list = []
          $this.pattern_product_data = []
          $this.pattern_id = ''
          $this.pattern_code = ''
          $this.pattern_name = ''
          $this.pattern_img = ''
          $this.example_picture = ''
          $this.order_type = 1
          $this.save_pattern = false

          // $('#example_picture').attr('src', '{{asset('img/default.png')}}');
        }

        if ($this.order_type === 1 || $this.order_type === '1') {
          $this.save_pattern = false
          $this.pattern_select = {}
          $this.pattern_product_list = []
          $this.pattern_product_data = []
        }

        $this.CheckAmountByProductType()
      } else {
        $this.validate_error.pattern = false
        $this.swalAlert('เกิดข้อผิดพลาด', 'error')

        return false
      }
    },
    checkData(Step) {
      const data = {
        order_id: this.$store.state.orderDetil.order_id,
        step: Step,
      }
      this.$store.state.isLoading = true
      instance_order.checkUpdateData(data).then(res => {
        this.$store.state.isLoading = false
        if (res.data.success == false) {
          Swal.fire({
            icon: 'error',
            title: res.data.message,
            showConfirmButton: false,
            timer: 3000,
          })
        } else {
          this.$store.state.step = Step

          if (!this.$store.state.orderDetil.recipient_name || this.$store.state.orderDetil.recipient_name == '') {
            this.$store.state.orderDetil.recipient_name = this.$store.state.member.member_name
          }

          if (!this.$store.state.orderDetil.recipient_address || this.$store.state.orderDetil.recipient_address == '') {
            this.$store.state.orderDetil.recipient_address = this.$store.state.member.member_shop_address
          }
        }
      }).catch(err => {
        this.$store.state.isLoading = false
        Swal.fire({
          icon: 'error',
          title: err.message,
          showConfirmButton: false,
          timer: 1500,
        })
      })
    },
    openBill() {
      this.$router.push({
        name: 'pages-order-detail',
        params: { id: this.$store.state.orderDetil.order_id },
      })
    },
    formsubmit(Step, next = true) {
      if (Step == -1) {
        this.$router.push({ name: 'pages-orders' })

        // instance_order.orderUpdatePaid().then(res => {
        //
        // }).catch(err => {})

        // this.$emit('close')
      } else {
        this.$store.state.checkUpdateClicked = false
        if (Step == 1 && next) {
          this.$store.state.step = Step
        } else if (Step == 2 && next) {
          this.checkData(Step)
        } else if (Step == 3 && next) {
          this.checkData(Step)
        } else if (Step == 4 && next) {
          this.checkData(Step)
        }
      }
    },
    getProductList() {
      if (this.$store.state.orderData && this.$store.state.orderData.length > 0) {
        let product_type_ids = ''
        let phone_brand_ids = ''
        const member_id = this.$store && this.$store.state && this.$store.state.member && this.$store.state.member.member_type_id ? this.$store.state.member.member_type_id : this.$store.state.orderDetil.member
        for (let i = 0; i < this.$store.state.orderData.length; i++) {
          if (i > 0) {
            phone_brand_ids += ','
            product_type_ids += ','
          }
          if (this.$store.state.orderData[i].phone_brand_id) {
            phone_brand_ids += (this.$store.state.orderData[i].phone_brand_id)

            // phone_brand_ids.push(this.$store.state.orderData[i].phone_brand_id)
          }

          if (this.$store.state.orderData[i].product_type_id) {
            product_type_ids += (this.$store.state.orderData[i].product_type_id)

            // product_type_ids.push(this.$store.state.orderData[i].product_type_id)
          }

          // product_type_ids += (this.$store.state.orderData[i].product_type_id)
          // product_type_ids.push(this.$store.state.orderData[i].product_type_id)
        }

        productAPI
          .getProductsByType(product_type_ids, phone_brand_ids, member_id)
          .then(res => {
            if (res.status === 200) {
              if (res.data && res.data.length > 0) {
                this.productList = res.data
                this.productListCopy = res.data
              } else {
                this.validate = true
                this.errorMessage = 'ไม่พบสินค้า'
              }
            }

            // removrToken
            user.removrToken(res)
          })
          .catch(err => {
            Swal.fire({
              icon: 'error',
              title: err.message,
              showConfirmButton: false,
              timer: 1500,
            })
          })
      }
    },
    changeFile(index) {
      const $this = this
      patternAPI
        .apiUpdatePattern(this.$store.state.orderData[index])
        .then(res => {
          if (res.status === 200) {
            this.getOrderDetail()
          }
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            title: err.message,
            showConfirmButton: false,
            timer: 1500,
          })
        })
    },
    orderUpdate() {
      orderAPI
        .orderUpdate(this.$store.state.orderDetil)
        .then(res => {
          if (res.status === 200) {
            this.getOrderDetail()
            Swal.fire({
              icon: 'success',
              title: 'บันทึกข้อมูลเรียบร้อย',
              showConfirmButton: false,
              timer: 1500,
            })
          }

          // removrToken
          user.removrToken(res)
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            title: err.message,
            showConfirmButton: false,
            timer: 1500,
          })
        })
    },
    validateForm() {
      this.$store.state.orderDetil.order_expense = this.total
      this.orderUpdate()
    },
    alertError(message) {
      alert(message)
    },

    // settransport() {
    //   if (this.$store.state.orderDetil.transport_type_id) {
    //     transportTypeAPI
    //       .apiTransportExpensePriceRate(this.amount, this.$store.state.orderDetil.transport_type_id)
    //       .then(res => {
    //         if (res.status === 200) {
    //           this.$store.state.orderDetil.transport_expense_price = res.data
    //           this.validateForm()
    //
    //         }
    //         // removrToken
    //         user.removrToken(res)
    //       })
    //       .catch(error => {
    //         console.log(error)
    //       })
    //   }
    // },
    remove(index) {
      const $this = this
      orderDetailAPI
        .delete($this.$store.state.orderData[index].order_detail_id)
        .then(res => {
          if (res.status === 200) {
            this.getOrderDetail()
          }

          // removrToken
          user.removrToken(res)
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            title: err.message,
            showConfirmButton: false,
            timer: 1500,
          })
        })

      // this.orderData.splice(index, 1)
    },

    // changeProduct(index, product_id) {
    //   this.orderData[index].productAmount = this.orderData[index].productAmoun
    //   productAmountAPI
    //     .getCheckAmount(product_id)
    //     .then(res => {
    //       if (res.status === 200) {
    //         this.orderData[index].productAmount = res.data
    //       }
    //       // removrToken
    //       user.removrToken(res)
    //     })
    //     .catch(error => {
    //       this.$swal({
    //         text: error.message,
    //         timer: 2000,
    //         icon: 'error',
    //         showConfirmButton: false,
    //         button: false
    //       })
    //     })
    // },
    changeProuctType(index) {
      this.$store.state.orderData[index].productSelect = []
      this.$store.state.orderData[index].phone_brand_id = ''
      this.$store.state.orderData[index].product_id = ''
    },
    changeModel(index) {
      this.$store.state.orderData[index].productSelect = []
      const { phone_brand_id } = this.$store.state.orderData[index]
      const { product_type_id } = this.$store.state.orderData[index]
      const $this = this
      const product = this.$store.state.orderData[index].product = {
        product: [],
      }
      this.$store.state.orderData[index].product_id = null
      productAPI
        .getProductByType(product_type_id, phone_brand_id, store.state.member)
        .then(res => {
          if (res.status === 200) {
            if (res.data && res.data.length > 0) {
              this.productList[index] = []
              this.productList[index] = res.data
              this.productListCopy[index] = res.data
              this.productList.splice(index, 1, res.data)
              this.productListCopy.splice(index, 1, res.data)
            } else {
              this.validate = true
              this.errorMessage = 'ไม่พบสินค้า'
            }
          }

          // removrToken
          user.removrToken(res)
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            title: err.message,
            showConfirmButton: false,
            timer: 1500,
          })
        })
    },
    uploadLink(index) {
      if (index == 0) {
        this.closeAddPicture()
        this.dialogAddPicture = true
        this.type_add = 1
      } else if (index == 1) {
        this.closeAddLink()
        this.dialogAddLink = true
        this.type_add = 2
      } else if (index == 2) {
        const { orderData } = this
        const { files } = event.target
        const data = {
          order_id: this.$store.state.orderDetil.order_id ?? '',
          member_id: this.$store.state.member.member_id ?? this.$store.state.orderDetil.member_id,
          pattern_name: files[0].name,
          pattern_detail: '',
          file: files[0],
        }

        patternAPI
          .apiAddPattern(data)
          .then(res => {
            if (res.status === 200) {
              if (res.data.success) {
                Swal.fire({
                  icon: 'success',
                  title: 'อัพโหลดสำเร็จ',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.getOrderDetail()
              } else {
                Swal.fire({
                  icon: 'error',
                  title: 'เกิดข้อผิดพลาด !!',
                  text: res.data.message,
                  showConfirmButton: false,
                  timer: 1500,
                })
              }
            }

            // removrToken
            user.removrToken(res)
          })
          .catch(err => {
            Swal.fire({
              icon: 'error',
              title: err.message,
              showConfirmButton: false,
              timer: 1500,
            })
          })
      }
    },
    closeAddPicture() {
      this.v$.$reset()
      this.dialogAddPicture = false

      this.$nextTick(() => {
        this.editAddPicture = { ...this.defaultEditAddPicture }
        this.editedIndex = -1
        this.st = ''
        this.product_search()
      })
    },
    closeAddLink() {
      this.v$.$reset()
      this.dialogAddLink = false
      this.$nextTick(() => {
        this.editAddLink = { ...this.defaultEditAddLink }
        this.editAddLink.list = []
        this.pattern_product_data = null
        this.st = ''
        this.product_search()
        this.editedIndex = -1
      })
    },
    async addPictureConfirm() {
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) return
      this.$store.state.isLoading = true

      const data = {
        order_id: this.$store.state.orderDetil.order_id ?? '',
        member_id: this.$store.state.member.member_id ?? this.$store.state.orderDetil.member_id,
        member_type_id: this.$store.state.member.member_type_id ?? this.$store.state.orderDetil.member_type_id,
        pattern_name: this.editAddPicture.name,
        pattern_detail: this.editAddPicture.description,
        product_amount: this.editAddPicture.product_amount,
        file: this.editAddPicture.file,
        file_example_picture: this.editAddPicture.image_example,
        product_id: this.editAddPicture.product_id,
      }

      patternAPI.apiAddPattern(data).then(res => {
        this.$store.state.isLoading = false
        if (res.data.success) {
          this.closeAddPicture()
          Swal.fire({
            icon: 'success',
            title: res.data.message,
            showConfirmButton: false,
            timer: 1500,
          })
          this.getOrderDetail()
          this.closeAddPicture()
        } else {
          Swal.fire({
            icon: 'error',
            title: res.data.message,
            showConfirmButton: false,
            timer: 1500,
          })
        }
      }).catch(function (error) {
        this.$store.state.isLoading = false
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด !!',
          text: error.message,
        })
      })
    },
    async addLinkConfirm() {
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) return
      this.$store.state.isLoading = true

      // this.validateOrder();

      // if (true) {
      //   // const { orderData } = store.state
      //   // if(files[0]){
      //   let orderData = this.orderData
      //
      //   let data = {
      //     order_id: this.$store.state.orderDetil.order_id ?? '',
      //     member_id: this.$store.state.member.member_id ?? this.$store.state.orderDetil.member_id,
      //     type_link_add_files: this.editAddLink.type_link_add_files
      //   }
      //
      //   patternAPI
      //     .apiAddPattern(data)
      //     .then(res => {
      //       if (res.status === 200) {
      //         if (res.data.success) {
      //
      //           this.closeAddLink()
      //           // Swal.fire({
      //           //   icon: 'success',
      //           //   title: "อัพโหลดสำเร็จ",
      //           //   showConfirmButton: false,
      //           //   timer: 1500
      //           // })
      //           this.getOrderDetail()
      //         } else {
      //
      //           Swal.fire({
      //             icon: 'error',
      //             title: 'เกิดข้อผิดพลาด !!',
      //             text: res.data.message,
      //             showConfirmButton: false,
      //             timer: 1500
      //           })
      //         }
      //       }
      //       // removrToken
      //       user.removrToken(res)
      //     })
      //     .catch(err => {
      //       Swal.fire({
      //         icon: 'error',
      //         title: err.message,
      //         showConfirmButton: false,
      //         timer: 1500
      //       })
      //     })
      //   // }
      //
      //
      // }

      // if (!this.pattern_name) {
      //   this.validate_error.pattern_name = false
      // } else {
      //   this.validate_error.pattern_name = true
      // }

      // if (!this.file && (this.type_files === 2 || this.type_files === '2')) {
      //   this.validate_error.file = false
      // } else {
      //   this.validate_error.file = true
      // }

      // if (!this.type_link_add_files && (this.type_files === 1 || this.type_files === '1')) {
      //   this.validate_error.type_link_add_files = false
      // } else {
      //   this.validate_error.type_link_add_files = true
      // }

      // if (!this.validate_error.recipient_name || !this.validate_error.recipient_address || !this.validate_error.file || !this.validate_error.pattern_name || !this.validate_error.transport_type) {
      //   this.swalAlert('กรุณากรอกข้อมูลให้ครบถ้วน', 'error')
      //   return false;
      // } else {
      //   check = true
      // }

      // let data = new FormData();
      // data.append('user_id', this.customer_select.member_id);
      // data.append('pattern_name', this.pattern_name);
      // data.append('pattern_code', this.pattern_code);
      // data.append('pattern_detail', this.pattern_detail);
      // data.append('file', this.file);
      // data.append('file_example_picture', this.file_example_picture);

      // let orderData = this.orderData
      // let files = event.target.files
      // let data = {
      //
      //
      //   pattern_name: files[0].name,
      //   pattern_detail: '',
      //   file: files[0]
      // }
      const data = {
        order_id: this.$store.state.orderDetil.order_id ?? '',
        member_id: this.$store.state.member.member_id ?? this.$store.state.orderDetil.member_id,
        member_type_id: this.$store.state.member.member_type_id ?? this.$store.state.orderDetil.member_type_id,
        pattern_name: this.editAddLink.name,
        pattern_detail: this.editAddLink.description,
        type_link_add_files: this.editAddLink.type_link_add_files,
        file_example_picture: this.editAddLink.image,
        list: this.editAddLink.list,
      }

      patternAPI.addPattern(data).then(res => {
        this.$store.state.isLoading = false
        if (res.data.success) {
          this.closeAddLink()
          Swal.fire({
            icon: 'success',
            title: res.data.message,
            showConfirmButton: false,
            timer: 1500,
          })
          this.getOrderDetail()
          this.closeAddPicture()
        }
      }).catch(function (error) {
        this.$store.state.isLoading = false
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด !!',
          text: error.message,
        })
      })
    },
    uploadFile(event, type) {
      this.validateOrder()

      if (!this.validate && type === 1) {
        // const { orderData } = store.state
        // if(files[0]){
        const { orderData } = this
        const { files } = event.target
        const data = {
          order_id: this.$store.state.orderDetil.order_id ?? '',
          member_id: this.$store.state.member.member_id ?? this.$store.state.orderDetil.member_id,
          pattern_name: files[0].name,
          pattern_detail: '',
          file: files[0],
        }

        patternAPI
          .apiAddPattern(data)
          .then(res => {
            if (res.status === 200) {
              if (res.data.success) {
                Swal.fire({
                  icon: 'success',
                  title: 'อัพโหลดสำเร็จ',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.getOrderDetail()
              } else {
                Swal.fire({
                  icon: 'error',
                  title: 'เกิดข้อผิดพลาด !!',
                  text: res.data.message,
                  showConfirmButton: false,
                  timer: 1500,
                })
              }
            }

            // removrToken
            user.removrToken(res)
          })
          .catch(err => {
            Swal.fire({
              icon: 'error',
              title: err.message,
              showConfirmButton: false,
              timer: 1500,
            })
          })

        // }
      }
    },
    product_search() {
      // if (this.pattern_select && this.pattern_select.product_type_id) {
      //   this.product_type_id = this.pattern_select.product_type_id
      // }
      // if (this.customer_select && this.customer_select.member_type_id) {
      //   this.validate_error.customer = true
      // }

      clearTimeout(this.delayTimer)
      this.delayTimer = setTimeout(() => {
        // Do the ajax stuff
        this.pattern_product = []
        const member_type_id = this.orderDetil.member
        const data = {
          member_type_id,
          product_type_id: this.pattern_select ? this.pattern_select.product_type_id : null,
          search: this.st ? this.st : '',
        }
        patternAPI.getPatternProduct(data).then(res => {
          this.pattern_product = []

          Object.entries(res.data.data).forEach(([key, val]) => {
            let product_amount = parseInt(val.product_amount)
            if (!product_amount || product_amount === '0' || product_amount === 0 || product_amount < 0) {
              product_amount = 0
            }

            const product = {
              image_path: val.image_path,
              phone_brand_name: val.phone_brand_name,
              phone_model_name: val.phone_model_name,
              product_code: val.product_code,
              pattern_code: this.pattern_select ? this.pattern_select.pattern_code : '',
              pattern_status: this.pattern_select ? this.pattern_select.pattern_status : '',
              product_costs: val.product_costs,
              product_price: val.price.product_price,
              product_price_old: val.product_price_old,
              remaining_online: val.remaining_online,
              product_id: val.product_id,
              product_type_id: val.product_type_id,
              product_name: val.product_name,

              // 'name': val.product_name + ' รุ่น: ' + val.phone_model_name + '  จำนวนคงเหลือ: ' + val.product_amount + ' (ชิ้น)',
              name: `${val.product_name}  จำนวนคงเหลือ: ${product_amount} (ชิ้น)`,
              product_amount,
              weight: val.weight,
            }
            this.pattern_product.push(product)
          })
        }).catch(err => {
          console.log(err)
        })
      }, 1500) // Will do the ajax stuff after 1000 ms, or 1 s
      // axios.get('{{url('admin/api_git_product')}}?member_type_id=' + member_type_id + '&&product_type_id=' + this.pattern_select.product_type_id,)
      // .then(response => {
    },

    // check จำนวนชิ้นค้า เกินกำหนด
    CheckAmount() {
      Object.entries(this.pattern_product_list).forEach(([key, val]) => {
        if (val.amount > val.product_amount) {
          this.pattern_product_list[key].amount = parseInt(val.product_amount)
        }
      })
    },
    add_pattern_product() {
      // //check จำนวนชิ้นค้า เกินกำหนด
      // this.product_search()
      // this.CheckAmount();
      // var check = true
      // Object.entries(this.pattern_product_list).forEach(([key, val]) => {
      //   if (val.product_id && this.pattern_product_data && val.product_id === this.pattern_product_data.product_id && this.pattern_product_data.product_amount > 0) {
      //     if (this.pattern_product_list[key].product_id === this.pattern_product_data.product_id &&
      //       this.pattern_product_list[key].phone_brand_name === this.pattern_product_data.phone_brand_name &&
      //       this.pattern_product_list[key].phone_model_name === this.pattern_product_data.phone_model_name) {
      //       this.pattern_product_list[key].amount = parseInt((this.pattern_product_list[key].amount + 1));
      //       check = false
      //       return false
      //     }
      //   }
      //
      // });
      //
      // if (check) {
      //   if (this.pattern_product_data && this.pattern_product_data.product_amount != 0 && this.pattern_product_data.product_amount > 0) {
      //     var $this = this
      //     let data = new FormData();
      //     data.append('member_id', $this.customer_select.member_id);
      //     data.append('product_type_id', $this.pattern_product_data.product_type_id);
      //     let config = {
      //       header: {
      //         'Content-Type': 'multipart/form-data'
      //       }
      //     }
      //     axios.post('{{url('api/member_package_prices')}}',
      //       data, config, {}
      //   )
      //   .then(function (response) {
      //       var package_price_cost = 0
      //       if (response.data && response.data.package_prices && response.data.package_prices != 0 && response.data.package_prices != '0.00') {
      //         package_price_cost = response.data.package_prices
      //       } else {
      //         package_price_cost = $this.pattern_product_data.product_price
      //       }
      //
      //       let pattern_product_data = {
      //         'image_path': $this.pattern_product_data.image_path,
      //         'phone_brand_name': $this.pattern_product_data.phone_brand_name,
      //         'phone_model_name': $this.pattern_product_data.phone_model_name,
      //         'product_code': $this.pattern_product_data.product_code,
      //
      //         'product_price': package_price_cost,
      //         'product_price_old': package_price_cost,
      //         'package_price_cost': package_price_cost,
      //         'product_costs': $this.pattern_product_data.product_costs,
      //         'product_id': $this.pattern_product_data.product_id,
      //         'product_type_id': $this.pattern_product_data.product_type_id,
      //         'product_name': $this.pattern_product_data.product_name,
      //         'weight': $this.pattern_product_data.weight,
      //         'product_amount': parseInt($this.pattern_product_data.product_amount),
      //         'amount': 1
      //       }
      //       $this.pattern_product_list.push(pattern_product_data)
      //
      //
      //     })
      //       .catch(function (error) {
      //         swal({
      //           text: error.message,
      //           timer: 3000,
      //           icon: 'error',
      //           showConfirmButton: false,
      //           button: false
      //         })
      //         console.log(error);
      //         return false
      //       });
      //
      //   } else {
      //     this.swalAlert('จำนวนคงเหลือสินค้าไม่เพียงพอ', 'error')
      //   }
      // }

    },
    searchProduct(index) {
      const vm = this
      const all = vm.productListCopy
      if (!vm.searchTerm[index]) {
        vm.productList.splice(index, 1, all[index])
      }

      const tmp = all[index].filter(product => product.product_name.indexOf(vm.searchTerm[index]) > -1)

      // vm.productList[index] = tmp;
      const abc = all
      vm.productList.splice(index, 1, tmp)
      vm.productListCopy.splice(index, 1, abc)
    },
    changeProduct() {
    },
    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split('-')

      return `${month}/${day}/${year}`
    },
    parseDate(date) {
      if (!date) return null
      const [month, day, year] = date.split('/')

      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    checkAmount(index) {
      if (this.$store.state.orderData[index].product_amount) {
        if (this.$store.state.orderData[index].product_type_id && this.$store.state.orderData[index].product_id && this.$store.state.orderData[index].phone_brand_id) {
          if (this.$store.state.orderData[index].product_amount > this.$store.state.orderData[index].product.remaining_online) {
            this.validate = true
            this.errorMessage = 'จำนวนสินค้าไม่เพียงพอ'
            this.$store.state.orderData[index].product_amount = 0
          } else {
            this.errorMessage = ''
            this.validate = false
          }
        }

        // this.settransport();
        if (!this.validate) {
          // updateOrderDetail to save
          this.updateOrderDetail(index)
        }
      }
    },
    changePrice(index) {
      // if (this.$store.state.orderData[index].product_type_id && this.$store.state.orderData[index].product_id && this.$store.state.orderData[index].phone_brand_id) {
      //   if (this.$store.state.orderData[index].product_amount > this.$store.state.orderData[index].product.remaining_online) {
      //     this.validate = true
      //     this.errorMessage = 'จำนวนสินค้าไม่เพียงพอ'
      //     this.$store.state.orderData[index].product_amount = 0;
      //   } else {
      //     this.errorMessage = ''
      //     this.validate = false
      //   }
      // }
      // // this.settransport();
      // if (!this.validate) {
      //   //updateOrderDetail to save
      this.updateOrderDetail(index)

      // }
    },
    validateOrder() {
      for (let i = 0; i < this.$store.state.orderData.length; i++) {
        if (!this.$store.state.orderData[i].product_type_id && !this.$store.state.orderData[i].product_id || !this.$store.state.orderData[i].phone_brand_id) {
          this.validate = true
          this.errorMessage = 'กรุณากรอกข้อมูลให้ครบถ้วนก่อนเพิ่มสินค้า'
          break
        }
      }

      for (let i = 0; i < this.$store.state.orderData.length; i++) {
        if (this.$store.state.orderData[i].product_amount > this.$store.state.orderData[i].product.remaining_online) {
          this.validate = true
          this.errorMessage = 'จำนวนสินค้าไม่เพียงพอ'
          break
        }
      }
    },
    setProductPrice(index, product_id) {
      for (let i = 0; i < this.$store.state.orderData[index].product.product.length; i++) {
        if (this.$store.state.orderData[index].product.product[i].product_id === product_id) {
          this.$store.state.orderData[index].product_price = parseInt(this.$store.state.orderData[index].product.product[i].product_costs)

          // console.log(this.orderData[index])
          break
        }
      }
    },

    checkProductDuplicate(index, product_id) {
      // let errorMessage = 'พบสินค้าซ้ำอยู่ในรายการ';
      // for (let i = 0; i < this.$store.state.orderData.length; i++) {
      //   if (this.$store.state.orderData[i].product_id === product_id) {
      //     this.validate = true
      //     this.errorMessage = errorMessage
      //     this.$store.state.orderData[index].product_id  = null
      //     this.alertError(errorMessage)
      //     break;
      //   }
      // }
    },

    productPrice(index) {
      const { product_id } = this.$store.state.orderData[index]
      const member_type_id = this.$store.state.member && this.$store.state.member.member_type_id ? this.$store.state.orderDetil.member.member_type_id : ''

      // this.checkProductDuplicate(index, product_id)
      this.validate = false
      if (!this.validate) {
        productPriceAPI
          .productPrice(product_id, member_type_id)
          .then(res => {
            if (res.status === 200) {
              if (res.data) {
                // check rate price
                this.$store.state.orderData[index].product_price = res.data
              } else {
                // check amount price
                this.$store.state.orderData[index].product_price = 0
                this.setProductPrice(index, product_id)
              }
              if (!this.validate) {
                // updateOrderDetail to save
                this.updateOrderDetail(index)
              }
            }

            // removrToken
            user.removrToken(res)
          })
          .catch(err => {
            Swal.fire({
              icon: 'error',
              title: err.message,
              showConfirmButton: false,
              timer: 1500,
            })
          })
      }
    },
    updateOrderDetail(index) {
      const order_expense = this.total
      orderDetailAPI
        .update(this.$store.state.orderData[index], order_expense)
        .then(res => {
          if (res.status === 200) {
            this.getOrderDetail()
          }

          // removrToken
          user.removrToken(res)
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            title: err.message,
            showConfirmButton: false,
            timer: 1500,
          })
        })
    },
  },
}
</script>

<style scoped>
.reset-padding-top {
  padding-top: 0 !important;
}

.v-input__slot {
  width: 50% !important;
}

.v-application--is-ltr .v-input__prepend-outer {
  margin-right: unset !important
}

.v-alert:not(.v-sheet--tile) {
  border-radius: 5px;
  margin-top: 10px;
}

a {
  text-decoration: none;
}

img {
  height: 5.15rem;
  width: 5.235rem;
  border-radius: 10px;
  /*margin-left: 30%;*/
}

li {
  list-style-type: none;
}

hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}

.testdialog {
  margin: 0 !important;
}

.wapper_content1 {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 23rem;
}

.wapperContentBottom {
  position: relative;
}

.wapperIconContent {
  transform: translateY(-100%);
  position: absolute;
  right: 36%;
}

.uploadFile {
  position: absolute;
  top: 15px;
  height: 35px;
  opacity: 0;
  cursor: pointer;
}

.img_proauctRes {

}

@media screen and (max-width: 600px) {
  .wapperIconContent {
    right: 25%;
  }
}
</style>
